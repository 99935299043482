import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '@ecster/i18n';
import {
    ButtonGroup,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogCloseButton,
    LabelValue,
    LinkButton,
} from '@ecster/components';

import { formatAmount } from '@ecster/util';
import { SimpleCopyToClipboardButton as CopyToClipboardButton } from '../../common/SimpleCopyToClipboardButton';

const {
    EcsterConfig: { paymentURL },
} = window;

export const PaymentDialog = ({ account, isOpen, onRequestClose }) => {
    const close = () => {
        onRequestClose(false);
    };

    return (
        <div className="payment-dialog">
            <Dialog maxWidth="532px" open={isOpen} onRequestClose={() => close()}>
                <>
                    <DialogCloseButton onRequestClose={() => close()} />
                    <DialogBody>
                        <h3 className="payment-dialog-header">{i18n('account.overview.payment-dialog.header')}</h3>
                        <p>{i18n('account.overview.payment-dialog.info-text', 'p')}</p>
                        <p>{i18n('account.overview.payment-dialog.sub-header')}</p>
                        <LabelValue
                            label={
                                <>
                                    <p>{i18n('account.overview.payment-dialog.account-number')}</p>
                                    <CopyToClipboardButton
                                        tooltip={i18n('account.overview.payment-dialog.copy-tooltip')}
                                        tooltipSuccess={i18n('account.overview.payment-dialog.copied-tooltip')}
                                        value={account.accountNumber}
                                    >
                                        <strong>{account.accountNumber}</strong>
                                    </CopyToClipboardButton>
                                </>
                            }
                            value={
                                <>
                                    <p>{i18n('account.overview.payment-dialog.credit-balance')}</p>
                                    <strong>{formatAmount(-1 * account.used)}</strong>
                                </>
                            }
                        />
                    </DialogBody>
                    <DialogFooter>
                        <ButtonGroup alignCenter>
                            <LinkButton round href={paymentURL}>
                                {i18n('account.overview.payment-dialog.link-text')}
                            </LinkButton>
                        </ButtonGroup>
                    </DialogFooter>
                </>
            </Dialog>
        </div>
    );
};

PaymentDialog.propTypes = {
    account: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};
